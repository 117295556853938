@import "../abstracts/variables";

::selection {
  background: transparent;
}

.blur {
  &-light {
    filter: blur(2px);
  }

  &-medium {
    filter: blur(3px);
  }

  &-high {
    filter: blur(4px);
  }
}

.text {
  &-blue {
    color: $blue;
  }

  &-green {
    color: $green;
  }

  &-pink {
    color: $pink;

    &-light {
      color: $pink-light;
    }
  }

  &-yellow {
    color: $yellow;
  }
}
