html,
body {
  margin: 0;
  overflow-x: hidden;
  overscroll-behavior: auto;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding: 2em;
  scroll-snap-type: y mandatory;
  vertical-align: baseline;
}

a,
body {
  position: relative;
  cursor: none;
}
