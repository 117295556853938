.navbar {
  background: linear-gradient(
    rgba(16, 16, 16, 0.75),
    rgba(16, 16, 16, 0.65) 17%,
    rgba(16, 16, 16, 0.6) 23%,
    rgba(16, 16, 16, 0.55) 29%,
    rgba(16, 16, 16, 0.5) 35%,
    rgba(16, 16, 16, 0.45) 41%,
    rgba(16, 16, 16, 0.4) 47%,
    rgba(16, 16, 16, 0.35) 52%,
    rgba(16, 16, 16, 0.3) 58%,
    rgba(16, 16, 16, 0.25) 64%,
    rgba(16, 16, 16, 0.2) 70%,
    rgba(16, 16, 16, 0.15) 76%,
    rgba(16, 16, 16, 0.1) 82%,
    rgba(16, 16, 16, 0.05) 88%,
    rgba(16, 16, 16, 0.01) 94%,
    transparent
  );
}
