@import "../vendors/bootstrap";
@import "../abstracts/variables";

header#hero {
  background-position: center 75%;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 -150px 100px rgba(0, 0, 0, 0.5);
  height: min(95vw, 700px);
  min-height: 480px;
}

main {
  section#concept {
    .backdrop {
      height: 100%;
      width: 100%;
      z-index: -1;

      img:nth-child(1) {
        transform: translate(1rem, 12rem);
      }

      img:nth-child(2) {
        transform: translate(25rem, 17rem);
      }

      img:nth-child(3) {
        transform: translate(-6rem, 24.5rem);
      }

      img:nth-child(4) {
        transform: translate(7.5rem, 30rem);
      }

      img:nth-child(5) {
        transform: translate(-3rem, 4rem);
      }

      img:nth-child(6) {
        transform: translate(20rem, 33rem);
      }
    }

    .concept {
      &__mockup {
        height: auto;
        width: 75%;
        transition: all ease 0.75s;

        &__profile {
          width: 235px;
        }

        &__camera {
          min-width: 550px;
          transform: translateX(2rem);
        }
      }

      &__info-box {
        transition: all ease 0.75s;
        transition-property: bottom, right;
        max-width: 425px;

        &__vibes {
          bottom: unset;
          position: relative;
          right: unset;
        }
      }
    }
  }

  section#attributes {
    .attributes__container {
      background-color: $dark;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, "md")) {
  main {
    section#concept {
      .concept__mockup__profile {
        width: 75%;
      }

      .concept__mockup__camera {
        transform: translateX(-10rem);
      }

      .concept__info-box__vibes {
        bottom: 30%;
        position: absolute;
        right: 20%;
      }
    }

    section#attributes {
      .attributes__container {
        background-color: transparent;
      }
    }

    @media (max-width: map-get($grid-breakpoints, "lg") - 1) {
      section#concept {
        .concept__mockup__camera {
          transform: translateX(-5rem);
        }

        .concept__info-box__vibes {
          right: 3%;
        }
      }
    }
  }
}
