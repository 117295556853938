// Bootstrap sans-serif font family
$font-family-sans-serif: "Poppins", sans-serif;

// Bootstrap and custom colors
$body-bg: #101010;
$primary: #cea8fb;
$dark: #202020;
$blue: #9cfcff;
$green: #b5ec6f;
$pink: #ff77b5;
$pink-light: #ffabed;
$yellow: #fef882;

// Bootstrap navbar
$navbar-toggler-font-size: 1rem;
$navbar-toggler-focus-width: 0;
$navbar-dark-toggler-border-color: transparent;
