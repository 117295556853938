.cursor {
  backdrop-filter: invert(100%);
  border-radius: 50%;
  display: none; // visibility is controlled in the script
  height: 1rem;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -50%);
  transform-origin: 100% 100%;
  transition: all 0.3s ease;
  transition-property: background, transform;
  width: 1rem;
  z-index: 2;
}

.link-grow {
  transform: scale(2);
}
